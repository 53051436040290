import React, { useState } from "react";
import { NavLink } from "react-router-dom";
import styles from "./Styles/Header.module.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBars } from "@fortawesome/free-solid-svg-icons";
import { faHome } from "@fortawesome/free-solid-svg-icons";
import { faNewspaper } from "@fortawesome/free-solid-svg-icons";
import { faFile } from "@fortawesome/free-solid-svg-icons";
import { faSignal } from "@fortawesome/free-solid-svg-icons";
import { faCalculator } from "@fortawesome/free-solid-svg-icons";
import { faInfo } from "@fortawesome/free-solid-svg-icons";
import image from "../images/logo_sbs.png";
import montenegro from "../images/montenegro.png";
import uk from "../images/united-kingdom.png";
import { useHistory } from "react-router-dom";

const Header = (props) => {
  const [dropdown, setDropdown] = useState(false);

  const toggle = React.useCallback(() => setDropdown(!dropdown));

  let history = useHistory();

  return (
    <React.Fragment>
      <div
        className={dropdown ? styles.backdrop : styles.invisible}
        onClick={toggle}
      />
      <div className={styles.header}>
        <div className={styles.logo}>
          <div className={styles.imgContainer}>
            <img src={image} alt="SBS" onClick={() => history.push("/")} />
          </div>
          <div
            className={dropdown ? styles.responsiveInvisible : styles.bars}
            onClick={toggle}
          >
            <FontAwesomeIcon icon={faBars} className="fa-2x" />
          </div>
        </div>

        <div className={dropdown ? styles.list : styles.invisible}>
          <nav>
            <ul>
              <li className={styles.link} onClick={toggle}>
                <button
                  className={styles.flagButton}
                  onClick={props.toggleLanguage}
                >
                  <span>
                    {props.language ? (
                      <img
                        className={styles.flagImage}
                        src={uk}
                        alt="English"
                      />
                    ) : (
                      <img
                        className={styles.flagImage}
                        src={montenegro}
                        alt="Montenegrin"
                      />
                    )}
                  </span>
                </button>
              </li>

              <li className={styles.link} onClick={toggle}>
                <NavLink
                  exact
                  to="/izvještaji"
                  activeStyle={{
                    fontWeight: "bold",
                    textDecoration: "underline",
                  }}
                >
                  <span>
                    <FontAwesomeIcon icon={faFile} className={styles.icon} />
                    {props.language ? "Izvještaji" : "Reports"}
                  </span>
                </NavLink>
              </li>

              <li className={styles.link} onClick={toggle}>
                <NavLink
                  exact
                  to="/kontakt"
                  activeStyle={{
                    fontWeight: "bold",
                    textDecoration: "underline",
                  }}
                >
                  <span>
                    <FontAwesomeIcon
                      icon={faCalculator}
                      className={styles.icon}
                    />
                    {props.language ? "Kalkulator" : "Calculator"}
                  </span>
                </NavLink>
              </li>

              <li className={styles.link} onClick={toggle}>
                <NavLink
                  exact
                  to="/usluge"
                  activeStyle={{
                    fontWeight: "bold",
                    textDecoration: "underline",
                  }}
                >
                  <span>
                    <FontAwesomeIcon icon={faSignal} className={styles.icon} />
                    {props.language ? "Usluge" : "Services"}
                  </span>
                </NavLink>
              </li>
              {/* <li className={styles.link} onClick={toggle}>
                <NavLink
                  exact
                  to="/novosti"
                  activeStyle={{
                    fontWeight: "bold",
                    textDecoration: "underline",
                  }}
                >
                  <span>
                    <FontAwesomeIcon
                      icon={faNewspaper}
                      className={styles.icon}
                    />
                    {props.language ? "Novosti" : "News"}
                  </span>
                </NavLink>
              </li> */}
              <li className={styles.link} onClick={toggle}>
                <NavLink
                  exact
                  to="/"
                  activeStyle={{
                    fontWeight: "bold",
                    textDecoration: "underline",
                  }}
                >
                  <span>
                    <FontAwesomeIcon icon={faHome} className={styles.icon} />
                    {props.language ? "Početna" : "Home"}
                  </span>
                </NavLink>
              </li>
            </ul>
          </nav>
        </div>
      </div>
      <hr />
    </React.Fragment>
  );
};

export default Header;
