import React, { useState, useEffect } from "react";
import axios from "axios";
import styles from "../Styles/Usluge.module.css";
import { Table } from "antd";
import { Select } from "antd";
import db from "../../firestore";
import opstiUslovi from "../../pdf/Opsti Uslovi.pdf";

const { Option } = Select;

const Tooway = (props) => {
  const [dataPaketi, setDataPaketi] = useState([]);
  const [dataProPaketi, setDataProPaketi] = useState([]);
  const [dataOstalo, setDataOstalo] = useState([]);
  const [stariPaketi1, setStariPaketi1] = useState([]);
  const [stariPaketi2, setStariPaketi2] = useState([]);
  const [tabela, setTabela] = useState("fizicka");

  useEffect(async () => {
    // const result = await axios("http://localhost:5000/paketi");
    const response = db.collection("paketi").orderBy("cijenaPaketa", "asc");
    const data = await response.get();
    let paketi = [];
    data.docs.forEach((item) => {
      paketi.push(item.data());
    });
    setDataPaketi(paketi);

    // const result2 = await axios("http://localhost:5000/pro");
    // paketi = [...result2.data];
    // paketi.forEach((paket) => {
    //   paket.imePaketa = paket.nazivPaketa;
    // });
    // setDataProPaketi(paketi);
    const response2 = db.collection("propaketi").orderBy("cijenaPaketa", "asc");
    const data2 = await response2.get();
    paketi = [];
    data2.docs.forEach((item) => {
      paketi.push(item.data());
    });
    setDataProPaketi(paketi);

    // const result3 = await axios("http://localhost:5000/dodatni");
    // paketi = [...result3.data];
    // setDataOstalo(paketi);
    const response3 = db.collection("dodatno");
    const data3 = await response3.get();
    paketi = [];
    data3.docs.forEach((item) => {
      paketi.push(item.data());
    });
    setDataOstalo(paketi);

    const response4 = db.collection("stari_paket_B2B");
    const data4 = await response4.get();
    paketi = [];
    data4.docs.forEach((item) => {
      paketi.push(item.data());
    });
    setStariPaketi1(paketi);

    const response5 = db.collection("stari_paketi_home");
    const data5 = await response5.get();
    paketi = [];
    data5.docs.forEach((item) => {
      paketi.push(item.data());
    });
    setStariPaketi2(paketi);
  }, []);

  let stariPaketi1Responsive = stariPaketi1.map((paket) => {
    return {
      ...paket,
      paketInfo: (
        <React.Fragment>
          <span className={styles.highlightName}>{paket.imePaketa}</span>
          <br />
          <span className={styles.highlight}>
            {props.language ? "Brzina - Kolčina" : "Speed - Amount"}
          </span>
          : {paket.opisPaketa} <br />
          <span className={styles.highlight}>
            {props.language ? "Cijena(€/mjesečno)" : "Price(€/monthly)"}
          </span>
          : {paket.cijenaPaketa}
        </React.Fragment>
      ),
    };
  });

  let stariPaketi2Responsive = stariPaketi2.map((paket) => {
    return {
      ...paket,
      paketInfo: (
        <React.Fragment>
          <span className={styles.highlightName}>{paket.imePaketa}</span>
          <br />
          <span className={styles.highlight}>
            {props.language ? "Brzina - Kolčina" : "Speed - Amount"}
          </span>
          : {paket.opisPaketa} <br />
          <span className={styles.highlight}>
            {props.language ? "Cijena(€/mjesečno)" : "Price(€/monthly)"}
          </span>
          : {paket.cijenaPaketa}
        </React.Fragment>
      ),
    };
  });

  let dataPaketiResponsive = dataPaketi.map((paket) => {
    return {
      ...paket,
      paketInfo: (
        <React.Fragment>
          <span className={styles.highlightName}>{paket.imePaketa}</span>
          <br />
          <span className={styles.highlight}>
            {props.language ? "Brzina - Kolčina" : "Speed - Amount"}
          </span>
          : {paket.opisPaketa} <br />
          <span className={styles.highlight}>
            {props.language ? "Cijena(€/mjesečno)" : "Price(€/monthly)"}
          </span>
          : {paket.cijenaPaketa}
        </React.Fragment>
      ),
    };
  });

  let dataProPaketiResponsive = dataProPaketi.map((paket) => {
    return {
      ...paket,
      paketInfo: (
        <React.Fragment>
          <span className={styles.highlightName}>{paket.imePaketa}</span>
          <br />
          <span className={styles.highlight}>
            {props.language ? "Brzina - Kolčina" : "Speed - Amount"}
          </span>
          : {paket.opisPaketa} <br />
          <span className={styles.highlight}>
            {props.language
              ? "Brzina nakon prekoračenja"
              : "Speed after overdraft"}
          </span>
          : {paket.opisPaketa2} <br />
          <span className={styles.highlight}>
            {props.language ? "Cijena(€/mjesečno)" : "Price(€/monthly)"}
          </span>
          : {paket.cijenaPaketa}
        </React.Fragment>
      ),
    };
  });

  let dataOstaloResponsive = dataOstalo.map((paket) => {
    return {
      ...paket,
      paketInfo: (
        <React.Fragment>
          <span className={styles.highlightName}>
            {props.language ? `${paket.naziv}` : `${paket.nazivEng}`}
          </span>
          <br />
          <span className={styles.highlight}>
            {props.language ? "Cijena(€)" : "Price(€)"}
          </span>
          : {paket.cijena}
        </React.Fragment>
      ),
    };
  });

  const columns = [
    {
      title: `${props.language ? "Dostupni Paketi" : "Avaliable packets"}`,
      responsive: ["xs"],
      dataIndex: "paketInfo",
      key: "paketInfo",
    },
    {
      title: `${props.language ? "Ime paketa" : "Name"}`,
      dataIndex: "imePaketa",
      key: "imePaketa",
      responsive: ["sm"],
    },
    {
      title: `${
        props.language
          ? "Brzina (Uplink/Downlink) - Mjesečna potrošnja"
          : "Speed (Uplink/Downlink) - Monthly consumption"
      }`,
      dataIndex: "opisPaketa",
      key: "opisPaketa",
      responsive: ["sm"],
    },
    {
      title: `${props.language ? "Cijena(€/mjesečno)" : "Price(€/monthly)"}`,
      dataIndex: "cijenaPaketa",
      key: "cijenaPaketa",
      responsive: ["sm"],
    },
  ];
  const columns2 = [
    {
      title: `${props.language ? "Dostupni Paketi" : "Avaliable packets"}`,
      responsive: ["xs"],
      dataIndex: "paketInfo",
      key: "paketInfo",
    },
    {
      title: `${props.language ? "Ime paketa" : "Name"}`,
      dataIndex: "imePaketa",
      key: "imePaketa",
      responsive: ["sm"],
    },
    {
      title: `${
        props.language
          ? "Brzina (Uplink/Downlink) - Mjesečna potrošnja"
          : "Speed (Uplink/Downlink) - Monthly consumption"
      }`,
      dataIndex: "opisPaketa",
      key: "opisPaketa",
      responsive: ["sm"],
    },
    {
      title: `${
        props.language
          ? "Brzina (Uplink/Downlink) - nakon mjesečne potrošnja"
          : "Speed (Uplink/Downlink) - After overdraft"
      }`,
      dataIndex: "opisPaketa2",
      key: "opisPaketa2",
      responsive: ["sm"],
    },
    {
      title: `${props.language ? "Cijena(€/mjesečno)" : "Price(€/monthly)"}`,
      dataIndex: "cijenaPaketa",
      key: "cijenaPaketa",
      responsive: ["sm"],
    },
  ];
  const columns3 = [
    {
      title: `${props.language ? "Dodatne usluge" : "Additional services"}`,
      responsive: ["xs"],
      dataIndex: "paketInfo",
      key: "paketInfo",
    },
    {
      title: `${props.language ? "Usluga" : "Service"}`,
      dataIndex: `${props.language ? "naziv" : "nazivEng"}`,
      key: "naziv",
      responsive: ["sm"],
    },
    {
      title: `${props.language ? "Cijena(€)" : "Price(€)"}`,
      dataIndex: "cijena",
      key: "cijena",
      responsive: ["sm"],
    },
  ];

  const handleChange = (event) => {
    setTabela(event);
  };

  return (
    <div className={styles.container}>
      <a className={styles.link} href={opstiUslovi} target="_blank">
        {props.language ? "Opšti Uslovi" : "General Contract Terms"}
      </a>
      <div className={styles.selectContainer}>
        <Select
          className={styles.select}
          value={tabela}
          onChange={(event) => handleChange(event)}
        >
          <Option value="fizicka">
            {props.language ? "Home servisi" : "Home Services"}
          </Option>
          <Option value="pravna">
            {props.language ? "Profesionalni servisi" : "Professional services"}
          </Option>
          <Option value="oprema">
            {props.language
              ? "Dodatne usluge i oprema"
              : "Additional services and equipment"}
          </Option>
          <Option value="stari1">
            {props.language ? "Stari paketi B2B" : "Deprecated B2B packets"}
          </Option>
          <Option value="stari2">
            {props.language ? "Stari Home paketi" : "Deprecated Home packets"}
          </Option>
        </Select>
      </div>
      <div className={styles.tableContainer}>
        {tabela === "fizicka" ? (
          <Table
            className={styles.table}
            pagination={false}
            dataSource={dataPaketiResponsive}
            columns={columns}
          />
        ) : tabela === "pravna" ? (
          <Table
            className={styles.table}
            pagination={false}
            dataSource={dataProPaketiResponsive}
            columns={columns2}
          />
        ) : tabela === "stari1" ? (
          <Table
            className={styles.table}
            pagination={false}
            dataSource={stariPaketi1Responsive}
            columns={columns}
          />
        ) : tabela === "stari2" ? (
          <Table
            className={styles.table}
            pagination={false}
            dataSource={stariPaketi2Responsive}
            columns={columns}
          />
        ) : (
          <Table
            className={styles.table}
            pagination={false}
            dataSource={dataOstaloResponsive}
            columns={columns3}
          />
        )}
      </div>
    </div>
  );
};

export default Tooway;
