import React, { useState, useEffect } from "react";
import axios from "axios";
import styles from "../Styles/Home.module.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import { faMinus } from "@fortawesome/free-solid-svg-icons";
import { faAddressCard } from "@fortawesome/free-solid-svg-icons";
import { faQuestionCircle } from "@fortawesome/free-solid-svg-icons";
import { faIndustry } from "@fortawesome/free-solid-svg-icons";
import Slider from "../Slider";
import SliderMob from "../SliderMob";
import { useHistory } from "react-router-dom";
import db from "../../firestore";
import karTerm from "../../pdf/Karakteristike Terminalne Opreme.pdf";

const Home = (props) => {
  const [data, setData] = useState([]);
  const [visible1, setVisible1] = useState(false);
  const [visible2, setVisible2] = useState(false);
  const [visible3, setVisible3] = useState(false);

  const toggleClass1 = () => {
    setVisible1(!visible1);
  };
  const toggleClass2 = () => {
    setVisible2(!visible2);
  };

  const toggleClass3 = () => {
    setVisible3(!visible3);
  };

  const history = useHistory();
  const RedirectToPaketi = () => {
    history.push("/usluge");
  };

  useEffect(async () => {
    // const result = await axios("http://localhost:5000/naj");
    // let paketi = [...result.data];
    // setData(paketi);
    // console.log(paketi);
    const response = db.collection("najpaketi").orderBy("cijenaPaketa", "asc");
    const dataa = await response.get();
    let paketi = [];
    dataa.docs.forEach((item) => {
      paketi.push(item.data());
    });
    setData(paketi);
  }, []);

  return (
    <div>
      <div className={styles.big}>
        {props.language ? "Kvalitetno rješenje" : "Reliable solution"}
        <br></br>
        {props.language ? " i vrhunska podrška" : "& first-class support"}
      </div>
      <div className={styles.sliderContainer}>
        <Slider language={props.language} data={data}></Slider>
      </div>
      <div className={styles.sliderMobContainer}>
        <SliderMob language={props.language} data={data}></SliderMob>
      </div>
      <div className={styles.boxes}>
        <div className={styles.containers}>
          <button className={styles.accordion} onClick={toggleClass1}>
            <FontAwesomeIcon icon={faAddressCard} />{" "}
            <span>{props.language ? "Više o nama" : "More about us"}</span>
            <FontAwesomeIcon
              style={{ float: "right" }}
              icon={visible1 ? faMinus : faPlus}
            />
          </button>

          <div className={visible1 ? styles.panel : styles.invisible}>
            {props.language ? (
              <p>
                Kompanija Satellite Broadband Service Network Montenegro je
                osnovana 2010. godine, sa sjedištem u Podgorici. Ova nova i
                savremena kompanija osnovana je sa ciljem da, po prvi put u
                Crnoj Gori, razvije i ponudi uslugu pristupa Internetu putem
                satelita. U pionirskom koracima ponude jednog od najnaprednijih
                servisa u oblasti satelitskih širokopojasnih usluga, Tooway, SBS
                Network Montenegro je za partnera izabrao renomiranu svjetsku
                kompaniju Skylogic. Tooway predstavlja satelitski servis koji
                obezbjeđuje najjednostavniji, brz i fleksibilan dvosmjerni
                širokopojasni pristup Internetu sa maksimalnom brzinom prilikom
                preuzimanja podataka (download) od 20 Mbps i maksimalnog brzinom
                postavljanja podataka (Upload) od 6 Mbps. SBS Net Montenegro
                svoju ponudu usmjerio je ka svim segmentima tržista: fizičkim
                licima, biznis sektoru i javnim institucijama. Raznovrsnost
                ponude garantuje da svako može naći odgovarajući, pristupačan i
                pouzdan paket koji ce zadovoljiti specijalne potrebe u skladu sa
                projektovanim obimom saobraćaja.
              </p>
            ) : (
              <p>
                Satelite Broadband Service Network Montenegro is a company
                established in 2010, located in Podgorica. This contemporary
                company has a goal to, for the first time in Montenegro, develop
                and offer internet access via satelite. SBS Net Montenegro
                choosed Skylogic as it's partner, in order to offer one of the
                most thriving services in domain of broadband services - Tooway.
                Tooway is a satelite broadband Internet service that provides
                customers with simple, fast and flexible two-way broadband
                internet access with maximum downlink speed of 20Mbps and
                maximum uplink speed of 6Mbps. SBS Network Montenegro adapted
                it's offer to every market segment : individuals, public sector
                and public institutions. This offers diversity guarantees an
                appropriate, accessible and reliable packet able to meet special
                requirements according to the requested traffic amount.
              </p>
            )}
          </div>
        </div>
        <div className={styles.containers}>
          <button className={styles.accordion} onClick={toggleClass2}>
            <FontAwesomeIcon icon={faQuestionCircle} />{" "}
            {props.language ? "Često postavljana pitanja" : "FAQ"}
            <FontAwesomeIcon
              style={{ float: "right" }}
              icon={visible2 ? faMinus : faPlus}
            />
          </button>
          <div className={visible2 ? styles.panel : styles.invisible}>
            {props.language ? (
              <p>
                <strong>Šta je Tooway?</strong> Tooway™ je visoko kvalitetni,
                pouzdan, korisnički satelitski širokopojasni (broadband) pristup
                Internetu za korisnike širom Evrope.
                <br />
                <strong>Zašto bi trebao kupiti Tooway™?</strong> Ako vam je na
                raspolaganju jedino dial-up način za pristup Internetu onda je
                Tooway pravo rješenje za vas. Nudi vam alternativu za
                širokopojasni Internet a posebno je pogodan za ruralna područja
                I područja gdje nema kablovske infrastrukture drugih Internet
                operatora. <br />
                <strong>Koja oprema mi treba?</strong> Sve što vam treba je
                satelitska antena prečnika 74 cm i satelitski modem. <br />
                <strong>Koliko je brz Tooway™?</strong> Tooway™ nudi downlink
                brzine do 30 Mbps pri Ku-band konfiguraciji. Uplink brzine su
                dostupne do 6 Mbps. <br />
                <strong>Mogu li instlirati Tooway™ samostalno?</strong> U
                principu je moguće. Poželjno je da instalaciju odradi
                sertifikovani instalater iz SBS Net Montenegro.
                <br />
                <strong>Koliko košta?</strong> Cjene zavise od izabranog paketa.
                Više detalja na
                <a
                  href=""
                  onClick={RedirectToPaketi}
                  className={styles.listaPaketa}
                >
                  {" "}
                  listi svih paketa
                </a>{" "}
                <br />
                <strong>Treba li mi telefonska linija?</strong> Ne, Tooway™ ne
                zahtijeva telefonsku liniju . <br />
                <strong>Postoje li minimalni zahtjevi za PC?</strong> Ne, bilo
                koji PC sa nekim od operativnih sistema (MAC, Windows ilii
                Linux) sa mrežnom karticom (10/100 Mbps) može biti povezan na
                satelitski modem. <br />
                <strong>Može li se Tooway™ integrisati u mrežu?</strong> Da,
                samo treba da povežete IP ruter izmedju mreže u vašoj kući I
                satelitskog modema. <br />
                <strong>
                  Mogu li koristiti bežičnu kućnu mrežu sa Tooway™?
                </strong>{" "}
                Da, bilo koji Wi-Fi ruter može biti povezan sa Tooway™ modemom.{" "}
                <br />
                <strong>Mogu li igrati online igre sa Tooway™?</strong> U
                principu da, ali nije preporučljivo igrati zahtjevne igrice jer
                je vrijeme odziva duže nego kod zemaljskih servisa (oko 600 –
                650 ms). <br />
                <strong>Na kojoj tehnologiji je baziran Tooway™?</strong>{" "}
                Tooway™ je baziran na ViaSat SurfBeam® DOCSIS® dvosmjernom
                širokopojasnom satelitskom sistemu.
                <br />
                <strong>
                  Koliko je kašnjenje pri prenosu podataka putem satelita?
                </strong>{" "}
                Kružno putovanje za prenos podataka do satelita je oko 250 msec.
                Dvosmjerni protokol ima kašnjenje oko 600 msec uključujući
                kašnjenje SurfBeam sistema. Da bi se pomenuto kašnjenje
                smanjilo, kako ne bi uticalo na TCP saobraćaj, u SurfBeam modemu
                I DOCSIS sistemu su implementirane funkcionalnost tzv.
                Performance Enhancing Proxy (PEP) I Web akceleracija. <br />
                <strong>
                  Koliko je stabilan Tooway servis kada je nevrijeme?
                </strong>{" "}
                Nekada se jaka kiša smatrala za potencijalnu barijeru uspješnom
                radu Ka satelitskih sistema. I zaista, smanjenje nivoa signala
                je toliko značajno da je bilo teško garantovati visokokvalitetnu
                uslugu. ViaSat je uključio u SurfBeam satelitski sistem, air
                interface tehnologiju koja rješava ovaj problem. SurfBeam system
                automatski odgovara na kišni fade sa uplink kontrolom napajanja
                i prilagodljivim tehnikama kodiranja podataka (Adaptive Coding
                and Modulation - ACM) koje prevazilaze potencijalna iskakanja,
                optimizirajući upotrebu satelitskog transponder bandwidth. Ova
                karakteristika čini Tooway™ mrežu pouzdanijom od drugih
                dvosmjernih korisničkih satelitskih usluga koje se nude u
                Evropi. <br />
                <strong>Koje frekvencije koristi Tooway™?</strong> Tooway™ radi
                u Ku- I Ka-band režimu. U Crnoj Gori koristi se Ku-band
                kapacitet na kompanijskom EUROBIRD™ 3 satelitu na 33° istočno.
              </p>
            ) : (
              <p>
                <strong>What is Tooway?</strong> Tooway™ is a high-quality,
                reliable, satelite broadband service for European customers.
                <br />
                <strong>Why shoud I use Tooway™?</strong> If your only way to
                access Internet is dial-up, then Tooway™ is a perfect solution
                for you as it offers an alternative for broadband Internet. It's
                especially suitable for rural areas and areas lacking cable
                infrastructure fo other Internet operators.
                <br />
                <strong>What equipment do I need?</strong> Everything You need
                is a satelite parabolic antenna, with diameter of 74cm and a
                Tooway™ satelite modem. <br />
                <strong>How fast is it?</strong> Tooway™ offers downlink speed
                up to 30 Mbps with Ku-band configuration. Upling speed goes up
                to 6 Mbps.
                <br />
                <strong>Can I install Tooway™ on my own?</strong> Generally yes,
                but we reccomend the installation to be done by certified SBS
                Network Montenegro installer. <br />
                <strong>What's the price?</strong> Price depends on the choosen
                package. More details at
                <a
                  href=""
                  onClick={RedirectToPaketi}
                  className={styles.listaPaketa}
                >
                  {" "}
                  packages list
                </a>{" "}
                <br />
                <strong>Do I need a telephone line?</strong>
                {""}No, Tooway™ does not require a telephone line.
                <br />
                <strong>Are there any PC requirements?</strong> No, any PC
                driven by any operating system (Windows, MAC or Linux) with
                network interface (10/100 Mbps) can be connected to satelite
                modem.
                <br />
                <strong>
                  Can Tooway™ be integrated into the network?
                </strong>{" "}
                Yes, you only have to connect IP router between yout home
                network and satelite modem.
                <br />
                <strong>
                  Can I use home wireless network with Tooway™?
                </strong>{" "}
                Yes, any Wi-Fi router can be connected to Tooway™ modem.
                <br />
                <strong>Can I play online games with Tooway™?</strong> Generally
                yes, but it's not reccomeded to play online games since round
                trip time is longer compared to ground services (around
                600-650ms) . <br />
                <strong>Which technology is Tooway™ based on?</strong>Tooway™ is
                based on ViaSat SurfBeam® DOCSIS® two-way broadband satelite
                system.
                <br />
                <strong>
                  How long is the propagation delay in satelite communications?
                </strong>
                A round trip time is about 250msec. Two-way protocol has a delay
                of about 600msec, including the SurBeam system delay. In order
                to minimize the aforementioned delay, and thus not affect TCP
                traffic, SurfBeam modem and DOCSIS system both have the
                Performance Enhancing Proxy (PEP) and Web acceleration
                implemented withih themselves.
                <br />
                <strong>
                  How stable is Tooway™ service during unhealthy hours (bad
                  weather)?
                </strong>
                Before, heavy rain was considered a potential barrier to the Ka
                satelite systems operaiting. Indeed, the significant reduction
                of signal level made it hard to maintain high performances of
                satelite services. To solve this problem, ViaSat included
                air-interface technology to SurfBeam system. SurfBeam system
                automatically responds to rain-fade with uplink power control
                and adaptable coding techniques (Adaptive Coding and Modulation
                - ACM) which overcome potential interruptions, optimizing the
                transponer bandwidth use. This attribute makes the Tooway™
                network more reliable then others European two-way satelite
                services.
                <br />
                <strong>Which frequencies Tooway™ uses?</strong> Tooway™
                operates in Ku and Ka-band mode. In Montenegro, Ku-band capacity
                is used on company EUROBIRD™ satelite at 33° east.
                <br />
              </p>
            )}
          </div>
        </div>
        <div className={styles.containers}>
          <button className={styles.accordion} onClick={toggleClass3}>
            <FontAwesomeIcon icon={faIndustry} />{" "}
            <span>{props.language ? "Tooway Servis" : "Tooway Service"}</span>
            <FontAwesomeIcon
              style={{ float: "right" }}
              icon={visible3 ? faMinus : faPlus}
            />
          </button>
          <div className={visible3 ? styles.panel : styles.invisible}>
            {props.language ? (
              <p>
                Kompanija Satellite Broadband Network Montenegro (SBS Net
                Montenegro), kao ovlašteni distributer Skylogic Tooway™ servisa
                za područje Crne Gore, nudi dvosmjerni satelitski Internet
                pristup bilo gdje u Crnoj Gori, nezavisno od zemaljske
                infrastrukture. Tooway™ je visokokvalitetni, satelitski
                širokopojasni Internet pristup za korisnike širom Evrope.
                Tooway™ servis je namijenjen svim korisnicima koji imaju potrebu
                za širokopojasnim vezama, a nemaju mogućnost korišćenja potrebne
                zemaljske infrastrukture. Servis je moguće primijeniti na
                mobilnim i stacionarnim objektima unutar oblasti koju pokriva
                satelit. Sistemi dijeljenog kapaciteta baziraju se na
                neograničenom prenosu podataka po pristupačnim cijenama uz
                stalnu prisutnost na Internetu. Tooway™ servis predstavlja novu
                generaciju širokopojasnog (broadband) Internet servisa koji je
                brži i fleksibilniji. Satelit je budućnost širokopojasnog
                (broadband) pristupa Internetu. Tooway™ koristi satelitsku
                tehnologiju za povezivanje korisnika sa brzim, fleksibilnim,
                širokopojasnim Internetom. Uprkos značajnom napretku tokom
                posljednjih godina, procijenjuje se da blizu 13 miliona
                domaćinstava širom Evrope nema pristup ADSL ili kablovskim
                mrežama. Zbog toga su Eutelsat i Viasat udružili snage kako bi
                pružili novi sistem za dvosmjerni širokopojasni pristup
                Internetu. Nemojte čekati na zemaljske distributere
                širokopojasnog Interneta. Sve što vam treba je eksterna Tooway™
                satelitska antena (slična onoj koju koristite za prijem TV
                signala) i satelitski modem. Nema instaliranja dodatnih programa
                ni dodatnih uređaja. SBS Net Montenegro je pripremio pakete koji
                će zadovoljiti kako vaše individualne zahtjeve tako i zahtjeve
                vaše porodice. Velika ponuda raznih paketa daje vam mogućnost da
                izaberete prenos one količine podataka koja će zadovoljiti vaše
                potrebe. Sve što vam je potrebno je Tooway satelitski modem i
                satelitska antena, i spremni ste za prijem širokopojasnog
                Interneta. Satelitski Internet koji nudi Tooway, za razliku od
                jednosmjernog satelitskog Interneta (samo smjer preuzimanja
                podataka - downlink only) gdje je neophodan telefonski
                priključak za vezu prema Internetu, je u potpunosti nezavisan,
                odnosno dvosmjeran. Dovoljna je satelitska veza pomoću tooway
                satelitske antene (90 cm) sa Ku-band radio dijelom i satelitskim
                modemom Surfbeam preko satelita EUROBIRD™ 3 na 33° istočno.
                Dvosmjerna veza, antenski sistem korisnika i prima i šalje
                podatke putem satelita prema Eutelsatovom centru u Torinu gdje
                se korisnik spaja na Internet. Ugovor za korišćenje usluge
                sklapa se na najmanje dvanaest mjeseci. Maksimalne brzine
                navedene su u tabeli (<a onClick={RedirectToPaketi}>Usluge</a>
                ), a količina podataka nije ograničena (FLAT RATE) što
                podrazumijeva da nema vremenskog ograničenja pristupa Internetu
                kao ni ograničenja ukupne prenesene količine podataka. Kao i kod
                svih drugih satelitskih davaoca usluga, sistem za svakog
                korisnika kontinuirano prati prosječnu karakteristiku protoka
                podataka kroz pet različitih intervala (svaki sat, svaka 4 sata,
                dnevno, sedmično i mjesečno), i određuje tzv. prag ("Threshold")
                u zavisnosti od odabranog paketa - tarife. Ukoliko korisnik
                značajno premašuje izračunati prag moguća su smanjenja brzine u
                okviru vršnih sati globalnog korištenja Interneta. Razlog ovome
                je činjenica da korisnici Tooway servisa dijele izvjesnu širinu
                pojasa. Da bi se ta širina održala stabilnom, sistem radi na
                principu tzv. "Fair Access Policy" ili FAP (Politika fer
                pristupa) Pristup Internetu sa visokim prioritetom imaju
                korisnici koji surfuju Internetom ili koriste mail servis
                Pristup Internetu sa niskim prioritetom imaju korisnici koji
                koriste peer-to-peer aplikacije (ovo se postiže ograničenjem
                propusnoog opsega) Sve što treba za satelitski pristup Internetu
                je dvosmjerna - "tooway" satelitska antena s radio dijelom i
                modem: Gdje se može koristiti Tooway™ servis? Pokrivenost širom
                Evrope Tooway™ radi na različitim frekvencijama, u zavisnosti od
                pokrivenosti satelitskim signalom. U Crnoj Gori se trenutno može
                korisiti satelit EUROBIRD™ 3 na 33° istočno (Ku-band). Za više
                informacija o terminalnoj opremi pogledajte{" "}
                <a className={styles.link} href={karTerm} target="_blank">
                  {props.language
                    ? "Karakteristike Terminalne Opreme"
                    : "Terminating Equipment Characteristics"}
                </a>{" "}
                .
              </p>
            ) : (
              <p>
                Satellite Broadband Service Network Montenegro (SBS Net
                Montenegro) is an authorized distributor of Skylogic Tooway
                service in the area of Montenegro. Tooway service offers two-way
                satelite Internet access anywhere in Montenegro, regardless of
                terrestial infrastructure. Tooway is a reliable service offered
                to users all across Europe. It is intended for all users who
                have a need for broadband connections and do not have the
                ability to use the necessary terrestial infrastructure. The
                service can be applied on mobile or stationary objects within
                the area covered by the satelite. Shared capacity systems are
                based on unlimited data transmission at affordable prices with a
                constant presence of the Internet. Tooway is a new generation of
                broadband Internet service that is faster and more flexible.
                Satelite is the future of broadband Internet access. Tooway uses
                satelite technology to connect users to high-speed, flexible,
                broadband internet. Despite significant advances in recent
                years, it is estimated that almost 13 milion households across
                Europe do not have access to ADSL or cable networks, which is
                why Eutelsat and Viasat have joined forces to provide a new
                system for two-way broadband Internet access. Satelite dish
                (similar to the one used for TV signals reception) and satelite
                modem is everything you need. No installation of additional
                programs or devices is required. Choose a package that will
                satisfy all your needs. SBS Net Montenegro has prepared packages
                that will meet both your individual requirements and the
                requirements of your family. Offers diversity provides you with
                a unique opportunity to choose the amount of data you need. All
                you need is a Tooway satelite modem and satelite dish, and you
                are ready to recieve broadband Internet. Two-way satelite
                internet offered by Tooway, unlike one way satelite internet
                (downlink only) where a telephone connection is needed to
                connect to the Internet is completely independent. Satelite
                connection via satelite dish (90cm) and a Ku-band radio
                transciever is enough to to provide you with Internet access
                over EUROBIRD 3 satelite at 33° east. Users antenna system sends
                and recieves the data towards Eutelsat's center in Turin, where
                the user is connected to the internet. User contract is signed
                with the minimal duration of 12 months. Maximal speeds are given
                in the table (
                <a onClick={RedirectToPaketi}>
                  {props.language ? "Usluge" : "Services"}
                </a>
                ), and the traffic (data) amount is not limited (FLAT RATE)
                which implies there is no time limit for Internet access as well
                as no restrictions regarding total amount of data transmitted.
                As for any other satelite Internet providers, system continously
                tracks the average amount of transmitted data through five
                different intervals (every hour, every 4 hours, daily, weekly
                and monthly) and determines the treshold based on the choosen
                packet - tariff. In case user significantly exceeds the
                calculated treshold , speed reductions are posible within the
                peak hours of global Internet use. The reason for this is the
                fact that Tooway service users share a certain bandwitdth. In
                order to keep that width stable, the system works on the
                principle of so-called "Fair Access policy". High priority
                Internet access is avaliable for users who surf the Internet or
                use mail service while low priority Internet access is provided
                to users who use peer-to-peer applications (this is achieved by
                bandwidth limitation). Tooway operates in different frequencies,
                depending on satellite signal coverage. In Montenegro, the
                EUROBIRD 3 satellite can currently be used at 33° east
                (Ku-band). For more information about terminal equipment, see{" "}
                <a className={styles.link} href={karTerm} target="_blank">
                  {props.language
                    ? "Karakteristike Terminalne Opreme"
                    : "Terminating Equipment Characteristics"}
                </a>{" "}
              </p>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Home;
