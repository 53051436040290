import React, { useEffect, useState } from "react";
import Footer from "./Components/Footer";
import Header from "./Components/Header";
import Contact from "./Components/Pages/Contact";

// import News from "./Components/Pages/News";
import Home from "./Components/Pages/Home";
import styles from "./App.module.css";
import Usluge from "./Components/Pages/Usluge";
import Izvjestaji from "./Components/Pages/Izvjestaji";

import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import WebFont from "webfontloader";

function App() {
  useEffect(() => {
    WebFont.load({
      google: {
        families: ["Sansita"],
      },
    });
  }, []);

  const [language, setLanguage] = useState(false);

  const toggleLanguage = React.useCallback(() => setLanguage(!language));

  return (
    <div className={styles.App}>
      <Router>
        <Header language={language} toggleLanguage={toggleLanguage} />
        <div>
          <Switch>
            <Route
              path="/izvještaji"
              exact
              component={() => <Izvjestaji language={language} />}
            ></Route>
            <Route
              path="/"
              exact
              component={() => <Home language={language} />}
            ></Route>
            <Route
              path="/kontakt"
              exact
              component={() => <Contact language={language} />}
            ></Route>
            {/* <Route
              path="/novosti"
              exact
              component={() => <News language={language} />}
            ></Route> */}
            <Route
              path="/usluge"
              exact
              component={() => <Usluge language={language} />}
            ></Route>
          </Switch>
        </div>
        <Footer language={language} />
      </Router>
    </div>
  );
}

export default App;
