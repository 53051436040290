import React from "react";
import "antd/dist/antd.css";
import styles from "./Styles/Slider.module.css";
import { Carousel } from "antd";
import CardComponent from "./Card";
import image from "../images/connecion.png";
import image2 from "../images/shake.png";
import { useHistory } from "react-router-dom";

const Slider = (props) => {
  const history = useHistory();
  const RedirectToPaketi = () => {
    history.push("/usluge");
  };

  return (
    <Carousel autoplay>
      <div>
        <div className={styles.small}>
          {props.language
            ? "Izaberite paket koji zadovoljava vaše potrebe"
            : "Choose a packet that perfectly meets your requirements"}

          <br />
          <p className={styles.paragraph}>
            {props.language
              ? "Najpopularniji paketi : "
              : "Most popular packets : "}
          </p>
          <br />
          <div className={styles.karte}>
            {props.data.map((paket) => {
              return (
                <CardComponent
                  key={paket.imePaketa}
                  opis={paket.opisPaketa}
                  title={paket.imePaketa}
                  cijena={paket.cijenaPaketa + "€/m"}
                />
              );
            })}
          </div>
          <button className={styles.listaPaketa} onClick={RedirectToPaketi}>
            {props.language ? "Lista svih paketa" : "List of all packets"}
          </button>
        </div>
      </div>
      <div>
        <div className={styles.small}>
          {props.language
            ? "Obezbjedite sebi stabilnu internet konekciju bilo gdje"
            : "Provide yourself a stable internet connection anywhere"}
        </div>
        <div className={styles.imageContainer}>
          <img src={image} alt="slika" />
        </div>
      </div>
      <div>
        <div className={styles.small}>
          {props.language
            ? "500+ zadovoljnih kupaca širom Crne Gore"
            : "500+ satisfied clients across Montenegro "}
        </div>
        <div className={styles.imageContainer}>
          <img src={image2} alt="slika" />
        </div>
      </div>
    </Carousel>
  );
};

export default Slider;
