import React, { useState } from "react";
import styles from "./Styles/Calculator.module.css";
import { Slider, Col, Row } from "antd";
import { useHistory } from "react-router-dom";

const Calculator = (props) => {
  const [mejlovi, setMejlovi] = useState(0);
  const [dokumenti, setDokumenti] = useState(0);
  const [slike, setSlike] = useState(0);
  const [surfovanje, setSurfovanje] = useState(0);
  const [socialMedia, setSocialMedia] = useState(0);
  const [muzika, setMuzika] = useState(0);
  const [snimci, setSnimci] = useState(0);
  const [filmovi, setFilmovi] = useState(0);
  const [stream, setStream] = useState(0);

  let potrosnja = (
    (mejlovi / 100 +
      dokumenti / 3 +
      slike +
      surfovanje * 10 +
      socialMedia * 120 +
      muzika * 3 +
      filmovi * 700 +
      stream * 900 +
      (snimci / 10) * 60) /
    1000
  ).toFixed(2);

  const inputsArray = [
    {
      label: `${
        props.language
          ? "Koliko mejlova šaljete mjesečno"
          : "How many emails do you send monthly"
      }`,
      max: 12000,
      name: "mejlovi",
    },
    {
      label: `${
        props.language
          ? "Koliko dokumenata upladujete/downloadujete mjesečno"
          : "How many documents do you upload/download monthly"
      }`,
      max: 1200,
      name: "dokumenti",
    },
    {
      label: `${
        props.language
          ? "Koliko slika uploadujete/downloadujete mjesečno"
          : "How many images do you uplaod/download monthly"
      }`,
      max: 500,
      name: "slike",
    },
    {
      label: `${
        props.language
          ? "Koliko sati dnevno surfujete internetom"
          : "How many hours a day you surf the internet"
      }`,
      max: 24,
      name: "surfovanje",
    },
    {
      label: `${
        props.language
          ? "Koliko sati dnevno provodite na društvenim mrežama"
          : "How many hours a day do you spend on social media networks"
      }`,
      max: 24,
      name: "socialMedia",
    },
    {
      label: `${
        props.language
          ? "Koliko muzičkih fajlova uploadujete/downloadujete mjesečno"
          : "How many music files do you upload/downlaod monthly"
      }`,
      max: 200,
      name: "muzika",
    },
    {
      label: `${
        props.language
          ? "Koliko video snimaka (prosječno 10 minuta) uploadujete/downloadujete mjesečno"
          : "How many video files (aprox. 10 minutes) do you upload/downlaod monthly"
      }`,
      max: 100,
      name: "snimci",
    },
    {
      label: `${
        props.language
          ? "Koliko filmova uploadujete/downloadujete mjesečno"
          : "How many movies do you upload/downlaod monthly"
      }`,
      max: 20,
      name: "filmovi",
    },
    {
      label: `${
        props.language
          ? "Koliko sati provodite gledajući filmove/streamove online mjesečno"
          : "How many hors do you spend watching livestreams/online movies monthly"
      }`,
      max: 50,
      name: "stream",
    },
  ];

  const onChange = (value, mark) => {
    switch (mark) {
      case "mejlovi":
        setMejlovi(value);
        break;
      case "dokumenti":
        setDokumenti(value);
        break;
      case "slike":
        setSlike(value);
        break;
      case "surfovanje":
        setSurfovanje(value);
        break;
      case "muzika":
        setMuzika(value);
        break;
      case "socialMedia":
        setSocialMedia(value);
        break;
      case "filmovi":
        setFilmovi(value);
        break;
      case "snimci":
        setSnimci(value);
        break;
      case "stream":
        setStream(value);
        break;
    }
  };

  let history = useHistory();

  return (
    <div className={styles.wrap}>
      <div className={styles.bigContainer}>
        <form>
          {inputsArray.map((input) => {
            return (
              <div className={styles.container} key={input.label}>
                <label htmlFor="sliderInputContainer" className={styles.white}>
                  {input.label}
                </label>
                <br />
                <div id="sliderInputContainer">
                  <Row>
                    <Col span={12}>
                      <Slider
                        min={0}
                        max={input.max}
                        onChange={(value) => onChange(value, input.name)}
                      />
                    </Col>
                  </Row>
                </div>
              </div>
            );
          })}
        </form>

        <hr />
        <div className={styles.whiteContainer}>
          <b>
            <span className={styles.highlight}>
              {props.language
                ? "Aproksimativna potrošnja:"
                : "Approximative consumption: "}
            </span>
            <span
              onClick={() => history.push("/usluge")}
              className={styles.redirect}
            >
              {potrosnja} Gb
              <br />
            </span>
            <span className={styles.highlight}>
              {" "}
              {props.language ? "Preporučeni paketi: " : "Recomended packets: "}
            </span>
            <span
              onClick={() => history.push("/usluge")}
              className={styles.redirect}
            >
              {potrosnja < 10
                ? " Konnect10 / Konnect10_DS*"
                : potrosnja < 20
                ? " Konnect10_DD / Konnect 10_DSD*"
                : potrosnja < 30
                ? " Konnect30 / Konnect 30_DS*"
                : potrosnja < 60
                ? " Konnect30_DD / Konnect 30_DSD*"
                : potrosnja < 75
                ? " Konnect75 / Konnect75_DS*"
                : potrosnja < 150
                ? " Konnect10_DD / Konnect 10_DSD*"
                : " Nijedan od naših servisa nije u mogućnosti da zadavolji vaše zahtjeve"}
            </span>
          </b>
        </div>
      </div>
    </div>
  );
};

export default Calculator;
