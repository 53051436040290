import firebase from "firebase";
var firebaseConfig = {
  apiKey: "AIzaSyAxkmTKx6fxSZfNHBbKXCAgWvq5wbJBcsA",
  authDomain: "sbsnet-7bf88.firebaseapp.com",
  projectId: "sbsnet-7bf88",
  storageBucket: "sbsnet-7bf88.appspot.com",
  messagingSenderId: "291592673227",
  appId: "1:291592673227:web:4eb40d8a4da1f1319c484e",
  measurementId: "G-4QTBT65Q88",
};

const firebaseApp = firebase.initializeApp(firebaseConfig);
const db = firebase.firestore();

export default db;
